.body-container {
    background-color: black;
    padding: 2rem 1rem;
}

.body > img {
    /* background-image: url('../Assets/img1_mobile.png'); */
    height: 100%;
    width: 100%;
}

.body > h2 {
    color: white;
}

.body > h2 > span {
    color: #AF7C4F;
    font-size: 1.5rem;  
    margin-top: 1rem;
}

@media screen and (min-width: 1440px) {

    .body-container {
        padding-top: 7rem;
    }

    .body {
        display: flex;
        flex-direction: row;
    }

    .body > img {
        height: 100%;
        width: 59%;
        margin-right: 4rem;
    }

    .body > h2 {
        color: white;
        font-size: 4rem;
        padding: 1rem;
    }

    
    .body > h2 > span {
        color: #AF7C4F;
        font-size: 4rem; 
        margin-top: -0.3rem;
        display: block;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1440px) {

    .body-container {
        padding-top: 7rem;
    }

    .body {
        display: flex;
        flex-direction: row;
    }

    .body > img {
        height: 100%;
        width: 59%;
        margin-right: 4rem;
    }

    .body > h2 {
        color: white;
        font-size: 3.5rem;
        padding: 1rem;
    }

    
    .body > h2 > span {
        color: #AF7C4F;
        font-size: 3.5rem; 
        margin-top: -0.3rem;
        display: block;
    }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {

    .body-container {
        padding-top: 7rem;
    }

    .body {
        display: flex;
        flex-direction: row;
    }

    .body > img {
        height: 100%;
        width: 59%;
        margin-right: 4rem;
    }

    .body > h2 {
        color: white;
        font-size: 3rem;
        padding: 1rem;
    }

    
    .body > h2 > span {
        color: #AF7C4F;
        font-size: 3rem; 
        margin-top: -0.3rem;
        display: block;
    }
}