.logo > img {
    width: 35%;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    margin-top: 0.3rem;
    width: 85%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.navBar {
    position: relative;
    z-index: 10;
}
  
.navBar button {
    position: relative;
    z-index: 10;
    cursor: pointer;
}
  
.menuNav {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 0;
    background: lightgray;
    left: 0;
    bottom: 0;
    height: 100vh;
    overflow: hidden;
    max-width: 18rem;
    border-radius: 0.5rem;
}

.menuNav.noShow {
    display: none;
}
  
.menuNav.showMenu {
    width: 100%;
    margin: 3.5rem 0rem;
    animation: slideIn 0.5s ease-in-out 1s forwards;
}


a { 
    display: block;
    padding: 1rem 2rem;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
}
  
.menuNav li:first-child {
    margin-top: 2.5rem;
}

.navBar button {
    border: none;
}

@media screen and (min-width: 768px) {
    .navbar-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 98%;
        margin: 0;
        border-radius: 0;
        background: none;
    }
    
    .logo > img {
        width: 12%;
        padding: 1rem;
        position: relative;
        left: 5rem;
    }
}