.header-2-container {
    /* background-image: url(''); */
    margin-top: -0.6rem;
    background-color: #F9EAE1;
}

.header-2-box-containers {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 2.5rem;
}

.header-2-box {
    padding: 0 1rem;
    margin: 0 auto;
    margin-top: 0.3rem;
    width: 10rem;
    height: 8rem;
    border-radius: 8px;
    background-color: black;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.header-2-box > h2 {
    color: white;
    font-size: 1.2rem;
    margin-top: 1rem;
    text-align: center;
}

#box1 {
    position: relative;
}

.header-2-box > h2 > span {
    color: #B17860;
}

.second-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    margin-top: -2rem;
    padding-bottom: 2rem;
}

@media screen and (min-width: 768px) {

    .header-2-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .header-2-box {
        width: 15rem;
        height: 13rem;
    }

    .header-2-box > h2 {
        font-size: 1.8rem;
        text-align: center;
        margin: 0 auto;
        padding: 2rem;
    }

    .header-2-box > h2 > span {
        font-size: 2.4rem;
    }

    .second-row {
        margin-top: 1.8rem;
        position: relative;
        right: 1.5rem;
    }
}